import React from "react";

import Image from "next/image";
import Link from "next/link";

import DIGITALMARKETING from "@/public/static/images/main_text-01.png";
import DIGITALMARKETINGactive from "@/public/static/images/main_text-01_active.png";
import ICON01 from "@/public/static/images/icon-main-wedo_01.png";

import EVENTACTIVATION from "@/public/static/images/main_text-02.png";
import EVENTACTIVATIONactive from "@/public/static/images/main_text-02_active.png";
import ICON02 from "@/public/static/images/icon-main-wedo_02.png";

import WEBSITEAPPLICATION from "@/public/static/images/main_text-03.png";
import WEBSITEAPPLICATIONactive from "@/public/static/images/main_text-03_active.png";
import ICON03 from "@/public/static/images/icon-main-wedo_03.png";

import GRAPHIC from "@/public/static/images/main_text-04.png";
import GRAPHICactive from "@/public/static/images/main_text-04_active.png";
import ICON04 from "@/public/static/images/icon-main-wedo_04.png";

import SALE from "@/public/static/images/main_text-05.png";
import SALEactive from "@/public/static/images/main_text-05_active.png";
import ICON05 from "@/public/static/images/icon-main-wedo_05.png";

import LOCALIZATION from "@/public/static/images/main_text-06.png";
import LOCALIZATIONactive from "@/public/static/images/main_text-06_active.png";
import ICON06 from "@/public/static/images/icon-main-wedo_06.png";

import PRINTING from "@/public/static/images/main_text-07.png";
import PRINTINGactive from "@/public/static/images/main_text-07_active.png";
import ICON07 from "@/public/static/images/icon-main-wedo_07.png";

import TRAINING from "@/public/static/images/main_text-08.png";
import TRAININGactive from "@/public/static/images/main_text-08_active.png";
import ICON08 from "@/public/static/images/icon-main-wedo_08.png";

import ECOMMERCE from "@/public/static/images/main_text-09.png";
import ECOMMERCEactive from "@/public/static/images/main_text-09_active.png";
import ICON09 from "@/public/static/images/icon-main-wedo_09.png";

import ButtonActive from "@/public/static/images/button_active.png";

import EastIcon from "@mui/icons-material/East";

function MainService() {
  const [scrollTop, setScrollTop] = React.useState(0);
  // const handleScroll = (event: any) => {
  //   console.log(event.currentTarget.scrollTop);
  //   setScrollTop(event.currentTarget.scrollTop);
  // };
  React.useEffect(() => {
    const ser001: any = document.getElementById("ser001");
    const ser002: any = document.getElementById("ser002");
    const ser003: any = document.getElementById("ser003");
    const ser004: any = document.getElementById("ser004");
    const ser005: any = document.getElementById("ser005");
    const ser006: any = document.getElementById("ser006");
    const ser007: any = document.getElementById("ser007");
    const ser008: any = document.getElementById("ser008");
    const ser009: any = document.getElementById("ser009");
    var dist = 400;
    const handleScroll = (event: any) => {
      if (!checkVisible(ser001, dist, "below")) {
        ser001.classList.add("hover");
      } else {
        ser001.classList.remove("hover");
      }
      if (!checkVisible(ser002, dist, "below")) {
        ser002.classList.add("hover");
      } else {
        ser002.classList.remove("hover");
      }
      if (!checkVisible(ser003, dist, "below")) {
        ser003.classList.add("hover");
      } else {
        ser003.classList.remove("hover");
      }
      if (!checkVisible(ser004, dist, "below")) {
        ser004.classList.add("hover");
      } else {
        ser004.classList.remove("hover");
      }
      if (!checkVisible(ser005, dist, "below")) {
        ser005.classList.add("hover");
      } else {
        ser005.classList.remove("hover");
      }
      if (!checkVisible(ser006, dist, "below")) {
        ser006.classList.add("hover");
      } else {
        ser006.classList.remove("hover");
      }
      if (!checkVisible(ser007, dist, "below")) {
        ser007.classList.add("hover");
      } else {
        ser007.classList.remove("hover");
      }
      if (!checkVisible(ser008, dist, "below")) {
        ser008.classList.add("hover");
      } else {
        ser008.classList.remove("hover");
      }
      if (!checkVisible(ser009, dist, "below")) {
        ser009.classList.add("hover");
      } else {
        ser009.classList.remove("hover");
      }
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    function checkVisible(elm: any, threshold: any, mode: any) {
      threshold = threshold || 0;
      mode = mode || "visible";

      var rect = elm.getBoundingClientRect();
      var viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      );
      var above = rect.bottom - threshold < 0;
      var below = rect.top - viewHeight + threshold >= 0;

      return mode === "above"
        ? above
        : mode === "below"
        ? below
        : !above && !below;
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="service-flip_box">
      <Link href="/ourservice/digital_marketing">
        <div className="flip-box sv-01" id="ser001">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              <div className="hstack gap-3">
                <div className="img-text p-2">
                  <Image loading="lazy"
                    src={DIGITALMARKETING}
                    alt="DIGITAL MARKETING"
                    className="img-fluid"
                  />
                </div>
                <div className="img-button_active align-items-end ms-auto">
                  <Image loading="lazy"
                    src={ICON01}
                    alt="DIGITAL MARKETING"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="flip-box-back">
              <div className="hstack gap-3">
                <div className="img-text p-2 text-start">
                  <Image loading="lazy"
                    src={DIGITALMARKETINGactive}
                    alt="DIGITAL MARKETING"
                    className="sv_text-active img-fluid "
                  />
                  <div className="sv-content">
                    <h3>Unleash your brand's potential</h3>
                    <p>
                      Our comprehensive digital marketing solutions are designed
                      <br />
                      to help you achieve any of your business goals.
                    </p>
                  </div>
                </div>
                <div className="img-button_active ms-auto align-items-end">
                  <Image loading="lazy"
                    src={ButtonActive}
                    alt="Read More"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <Link href="/ourservice/event_activation">
        <div className="flip-box sv-02" id="ser002">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              <div className="hstack gap-3">
                <div className="img-text p-2">
                  <Image loading="lazy"
                    src={EVENTACTIVATION}
                    alt="EVENT & ACTIVATIONG"
                    className="img-fluid"
                  />
                </div>
                <div className="img-button_active align-items-end ms-auto">
                  <Image loading="lazy"
                    src={ICON02}
                    alt="EVENT & ACTIVATION"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="flip-box-back">
              <div className="hstack gap-3">
                <div className="img-text p-2 text-start">
                  <Image loading="lazy"
                    src={EVENTACTIVATIONactive}
                    alt="EVENT & ACTIVATION"
                    className="sv_text-active img-fluid "
                  />
                  <div className="sv-content">
                    <h3>From concept to execution</h3>
                    <p>
                      We provide end-to-end event and activation solutions with
                      <br />
                      our experienced team of event planners and coordinators.
                    </p>
                  </div>
                </div>
                <div className="img-button_active ms-auto align-items-end">
                  <Image loading="lazy"
                    src={ButtonActive}
                    alt="Read More"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <Link href="/ourservice/web_application">
        <div className="flip-box sv-03" id="ser003">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              <div className="hstack gap-3">
                <div className="img-text p-2">
                  <Image loading="lazy"
                    src={WEBSITEAPPLICATION}
                    alt="WEBSITE & APPLICATION"
                    className="img-fluid"
                  />
                </div>
                <div className="img-button_active align-items-end ms-auto">
                  <Image loading="lazy"
                    src={ICON03}
                    alt="WEBSITE & APPLICATION"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="flip-box-back">
              <div className="hstack gap-3">
                <div className="img-text p-2 text-start">
                  <Image loading="lazy"
                    src={WEBSITEAPPLICATIONactive}
                    alt="WEBSITE & APPLICATION"
                    className="sv_text-active img-fluid "
                  />
                  <div className="sv-content">
                    <h3>Custom solutions for your unique needs</h3>
                    <p>
                      We work closely with you to create website application and
                      mobile application
                      <br />
                      that's tailored to your business goals and audience.
                    </p>
                  </div>
                </div>
                <div className="img-button_active ms-auto align-items-end">
                  <Image loading="lazy"
                    src={ButtonActive}
                    alt="Read More"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <Link href="/ourservice/design_media">
        <div className="flip-box sv-04" id="ser004">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              <div className="hstack gap-3">
                <div className="img-text p-2">
                  <Image loading="lazy"
                    src={GRAPHIC}
                    alt="GRAPHIC DESIGN 2D / 3D"
                    className="img-fluid"
                  />
                </div>
                <div className="img-button_active align-items-end ms-auto">
                  <Image loading="lazy"
                    src={ICON04}
                    alt="GRAPHIC DESIGN 2D / 3D"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="flip-box-back">
              <div className="hstack gap-3">
                <div className="img-text p-2 text-start">
                  <Image loading="lazy"
                    src={GRAPHICactive}
                    alt="GRAPHIC DESIGN 2D / 3D"
                    className="sv_text-active img-fluid "
                  />
                  <div className="sv-content">
                    <h3>Bring your ideas to life</h3>
                    <p>
                      Our services are designed to help you turn your ideas into
                      reality,
                      <br />
                      with our experienced team, any of your ideas can be true.
                    </p>
                  </div>
                </div>
                <div className="img-button_active ms-auto align-items-end">
                  <Image loading="lazy"
                    src={ButtonActive}
                    alt="Read More"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <Link href="/ourservice/sales_promotion_tools">
        <div className="flip-box sv-05" id="ser005">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              <div className="hstack gap-3">
                <div className="img-text p-2">
                  <Image loading="lazy"
                    src={SALE}
                    alt="SALES PROMOTION TOOLS"
                    className="img-fluid"
                  />
                </div>
                <div className="img-button_active align-items-end ms-auto">
                  <Image loading="lazy"
                    src={ICON05}
                    alt="SALES PROMOTION TOOLS"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="flip-box-back">
              <div className="hstack gap-3">
                <div className="img-text p-2 text-start">
                  <Image loading="lazy"
                    src={SALEactive}
                    alt="SALES PROMOTION TOOLS"
                    className="sv_text-active img-fluid "
                  />
                  <div className="sv-content">
                    <h3>
                      Experience the benefits of a comprehensive sales promotion
                      strategy
                    </h3>
                    <p>
                      Our team can help you develop and implement a sales
                      promotion strategy
                      <br />
                      that's customized to your business needs, goals, and
                      target audience.
                    </p>
                  </div>
                </div>
                <div className="img-button_active ms-auto align-items-end">
                  <Image loading="lazy"
                    src={ButtonActive}
                    alt="Read More"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <Link href="/ourservice/localization">
        <div className="flip-box sv-06" id="ser006">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              <div className="hstack gap-3">
                <div className="img-text p-2">
                  <Image loading="lazy"
                    src={LOCALIZATION}
                    alt="LOCALIZATION"
                    className="img-fluid"
                  />
                </div>
                <div className="img-button_active align-items-end ms-auto">
                  <Image loading="lazy"
                    src={ICON06}
                    alt="LOCALIZATION"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="flip-box-back">
              <div className="hstack gap-3">
                <div className="img-text p-2 text-start">
                  <Image loading="lazy"
                    src={LOCALIZATIONactive}
                    alt="LOCALIZATION"
                    className="sv_text-active img-fluid "
                  />
                  <div className="sv-content">
                    <h3>Expand your business horizons</h3>
                    <p>
                      From translation to localization, we've got you covered
                      for all your product needs in different languages.
                    </p>
                  </div>
                </div>
                <div className="img-button_active ms-auto align-items-end">
                  <Image loading="lazy"
                    src={ButtonActive}
                    alt="Read More"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <Link href="/ourservice/printing_logistics">
        <div className="flip-box sv-07" id="ser007">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              <div className="hstack gap-3">
                <div className="img-text p-2">
                  <Image loading="lazy"
                    src={PRINTING}
                    alt="PRINTING & LOGISTICS"
                    className="img-fluid"
                  />
                </div>
                <div className="img-button_active align-items-end ms-auto">
                  <Image loading="lazy"
                    src={ICON07}
                    alt="PRINTING & LOGISTICS"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="flip-box-back">
              <div className="hstack gap-3">
                <div className="img-text p-2 text-start">
                  <Image loading="lazy"
                    src={PRINTINGactive}
                    alt="PRINTING & LOGISTICS"
                    className="sv_text-active img-fluid "
                  />
                  <div className="sv-content">
                    <h3>Get your message out there</h3>
                    <p>
                      Our team of printing professionals takes care of every
                      step of the printing process, from design to delivery.
                    </p>
                  </div>
                </div>
                <div className="img-button_active ms-auto align-items-end">
                  <Image loading="lazy"
                    src={ButtonActive}
                    alt="Read More"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <Link href="/ourservice/training_tools">
        <div className="flip-box sv-08" id="ser008">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              <div className="hstack gap-3">
                <div className="img-text p-2">
                  <Image loading="lazy"
                    src={TRAINING}
                    alt="TRAINING TOOLS"
                    className="img-fluid"
                  />
                </div>
                <div className="img-button_active align-items-end ms-auto">
                  <Image loading="lazy"
                    src={ICON08}
                    alt="TRAINING TOOLS"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="flip-box-back">
              <div className="hstack gap-3">
                <div className="img-text p-2 text-start">
                  <Image loading="lazy"
                    src={TRAININGactive}
                    alt="TRAINING TOOLS"
                    className="sv_text-active img-fluid "
                  />
                  <div className="sv-content">
                    <h3>Eliminate your burden of employee training</h3>
                    <p>
                      With our one-stop service of Training Tools production,
                      your training process will be a lot easier.
                    </p>
                  </div>
                </div>
                <div className="img-button_active ms-auto align-items-end">
                  <Image loading="lazy"
                    src={ButtonActive}
                    alt="Read More"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <Link href="/ourservice/ecommerce">
        <div className="flip-box sv-09" id="ser009">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              <div className="hstack gap-3">
                <div className="img-text p-2">
                  <Image loading="lazy"
                    src={ECOMMERCE}
                    alt="E-COMMERCE"
                    className="img-fluid"
                  />
                </div>
                <div className="img-button_active align-items-end ms-auto">
                  <Image loading="lazy" src={ICON09} alt="E-COMMERCE" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="flip-box-back">
              <div className="hstack gap-3">
                <div className="img-text p-2 text-start">
                  <Image loading="lazy"
                    src={ECOMMERCEactive}
                    alt="E-COMMERCE"
                    className="sv_text-active img-fluid "
                  />
                  <div className="sv-content">
                    <h3>Streamline your e-commerce operations</h3>
                    <p>
                      Our E-Commerce Management services help you create a
                      unique and engaging online store
                      <br />
                      that resonates with your target audience and drives
                      conversions.
                    </p>
                  </div>
                </div>
                <div className="img-button_active ms-auto align-items-end">
                  <Image loading="lazy"
                    src={ButtonActive}
                    alt="Read More"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default MainService;
