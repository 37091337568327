import React, { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import DigitalMarketing from "@/public/static/images/1_thumbnail_digital-marketing.jpg";
import EventActivation from "@/public/static/images/2_thumbnail_event-&-activation.jpg";
import WebsiteApplication from "@/public/static/images/3_thumbnail_website-&-application.jpg";
import GraphicDesign from "@/public/static/images/4_thumbnail_graphic-design-2d-&-3d.jpg";
import SalesPromotion from "@/public/static/images/5_thumbnail_sales-promotion-tools.jpg";
import Localization from "@/public/static/images/6_thumbnail_localization.jpg";
import PrintingLogistics from "@/public/static/images/7_thumbnail_printing-&-logistics.jpg";
import TrainingTools from "@/public/static/images/8_thumbnail_training-tools.jpg";
import Ecommerce from "@/public/static/images/9_thumbnail_e-commerce.jpg";
import EastIcon from "@mui/icons-material/East";

function NewsBox({ rows, page, perPage, lang }: any) {
  if (!rows) {
    rows = [];
  }
  let index, offSet;
  if (page == 1 || page <= 0) {
    index = 0;
    offSet = perPage;
  } else if (page > rows.length) {
    index = page - 1;
    offSet = rows.length;
  } else {
    index = page * perPage - perPage;
    offSet = index + perPage;
  }
  const slicedItems = rows.slice(index, offSet);
  return (
    <div className="row row-cols-1 row-cols-md-3 g-4">
      {slicedItems.length > 0 &&
        slicedItems.map((value: any, key: any) => {
          if (value.status == "Publish") {
            return (
              <div key={key} className="col zoom newsbox">
                <Link
                  href={{
                    pathname: `news/${value.news_id}/${value.topic}`,
                    query: { lang: lang },
                  }}
                >
                  <div className="card h-100">
                    <div className="img-card h200">
                      <Image
                        loading="lazy"
                        unoptimized
                        src={value.thumbnail}
                        alt={value.topic}
                        className="h-100 w-100 object-fit-contain"
                        width={500}
                        height={300}
                      />
                    </div>

                    <div className="card-body">
                      <h3 className="card-title mb-0 text-truncate">
                        {value.topic}
                      </h3>
                      <p className="date mb-2">{value.post_date}</p>
                      <Stack spacing={2} alignItems="left">
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label={value.category}
                            className="categorize_s mb-3"
                          />
                          <Chip
                            label={value.service}
                            className="categorize_c mb-3"
                          />
                        </Stack>
                      </Stack>
                      <p className="card-text">{value.short_description}</p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
}

export default NewsBox;
