import React from "react";
import Image from "next/image";
import Link from "next/link";
import Project1 from "@/public/static/images/01_thumbnail_project.png";
import Project2 from "@/public/static/images/02_thumbnail_project.png";
import Project3 from "@/public/static/images/03_thumbnail_project.png";
import Project4 from "@/public/static/images/04_thumbnail_project.png";
import Project5 from "@/public/static/images/05_thumbnail_project.png";
import Project6 from "@/public/static/images/06_thumbnail_project.png";
import Project7 from "@/public/static/images/07_thumbnail_project.png";
import Project8 from "@/public/static/images/08_thumbnail_project.png";
import Project9 from "@/public/static/images/09_thumbnail_project.png";
import Project10 from "@/public/static/images/10_thumbnail_project.png";
import Project11 from "@/public/static/images/11_thumbnail_project.png";
import Project12 from "@/public/static/images/12_thumbnail_project.png";

function Project() {
  const [scrollTop, setScrollTop] = React.useState(0);
  // const handleScroll = (event: any) => {
  //   console.log(event.currentTarget.scrollTop);
  //   setScrollTop(event.currentTarget.scrollTop);
  // };

  React.useEffect(() => {
    const fig001: any = document.getElementById("fig001");
    const fig003: any = document.getElementById("fig003");
    const fig004: any = document.getElementById("fig004");
    const fig005: any = document.getElementById("fig005");
    const fig006: any = document.getElementById("fig006");
    const fig007: any = document.getElementById("fig007");
    const fig008: any = document.getElementById("fig008");
    const fig009: any = document.getElementById("fig009");
    const fig010: any = document.getElementById("fig010");
    const fig011: any = document.getElementById("fig011");
    const fig012: any = document.getElementById("fig012");
    var dist = 300;
    const handleScroll = (event: any) => {
      if (!checkVisible(fig001, dist, "below")) {
        fig001.classList.add("hover");
      } else {
        fig001.classList.remove("hover");
      }
      if (!checkVisible(fig003, dist, "below")) {
        fig003.classList.add("hover");
      } else {
        fig003.classList.remove("hover");
      }
      if (!checkVisible(fig004, dist, "below")) {
        fig004.classList.add("hover");
      } else {
        fig004.classList.remove("hover");
      }
      if (!checkVisible(fig005, dist, "below")) {
        fig005.classList.add("hover");
      } else {
        fig005.classList.remove("hover");
      }
      if (!checkVisible(fig006, dist, "below")) {
        fig006.classList.add("hover");
      } else {
        fig006.classList.remove("hover");
      }
      if (!checkVisible(fig007, dist, "below")) {
        fig007.classList.add("hover");
      } else {
        fig007.classList.remove("hover");
      }
      if (!checkVisible(fig008, dist, "below")) {
        fig008.classList.add("hover");
      } else {
        fig008.classList.remove("hover");
      }
      if (!checkVisible(fig009, dist, "below")) {
        fig009.classList.add("hover");
      } else {
        fig009.classList.remove("hover");
      }
      if (!checkVisible(fig010, dist, "below")) {
        fig010.classList.add("hover");
      } else {
        fig010.classList.remove("hover");
      }
      // if (!checkVisible(fig011, dist, "below")) {
      //   fig011.classList.add("hover");
      // } else {
      //   fig011.classList.remove("hover");
      // }
      if (!checkVisible(fig012, dist, "below")) {
        fig012.classList.add("hover");
      } else {
        fig012.classList.remove("hover");
      }
      setScrollTop(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    function checkVisible(elm: any, threshold: any, mode: any) {
      threshold = threshold || 0;
      mode = mode || "visible";

      var rect = elm.getBoundingClientRect();
      var viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      );
      var above = rect.bottom - threshold < 0;
      var below = rect.top - viewHeight + threshold >= 0;

      return mode === "above"
        ? above
        : mode === "below"
        ? below
        : !above && !below;
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-4 mt-5">
      <div className="col grid">
        <Link href="/project/itpsuiteapp">
          <div className="grid-dot">
            <figure className="effect-honey bg-pink p-01" id="fig001">
              <div className="heading-proj">
                <h1>
                  ITP SUITE <br />
                  APPLICATION
                </h1>
              </div>
              <figcaption>
                <div className="a-project">
                  <Image
                    loading="lazy"
                    src={Project1}
                    alt="ITP SUITE APPLICATION"
                    className="img-fluid"
                  />
                </div>
              </figcaption>
              <div className="text-proj line-twin">
                <p>
                  An all-in-one application for the company's internal usage:
                  From reserving resources to checking attendance.
                </p>
              </div>
            </figure>
          </div>
        </Link>
      </div>

      <div className="col grid">
        <Link href={"https://shopee.co.th/madoojapan"}>
          <div className="grid-dot">
            <figure className="effect-honey bg-blue p-09" id="fig009">
              <div className="heading-proj stroke">
                <h1>MDJ SHOP</h1>
              </div>
              <figcaption>
                <div className="a-project r-img-center">
                  <Image
                    loading="lazy"
                    src={Project9}
                    alt="MDJ SHOP"
                    className="img-fluid"
                  />
                </div>
              </figcaption>
              <div className="text-proj line-single">
                <p>
                  Our shop full of Japanese quality goods, selected by heart
                  that will brighten up every slice of your life
                </p>
              </div>
            </figure>
          </div>
        </Link>
      </div>

      <div className="col grid">
        <Link href="/project/virtualbooth">
          <div className="grid-dot">
            <figure className="effect-honey bg-green p-03" id="fig003">
              <div className="heading-proj">
                <h1>VIRTUAL BOOTH</h1>
              </div>
              <figcaption>
                <div className="a-project a-img-center">
                  <Image
                    loading="lazy"
                    src={Project3}
                    alt="VIRTUAL BOOTH"
                    className="img-fluid"
                  />
                </div>
              </figcaption>
              <div className="text-proj line-single">
                <p>
                  Enhance your customer experience on your website by creating
                  Virtual Booth to present any of your products.
                </p>
              </div>
            </figure>
          </div>
        </Link>
      </div>

      <div className="col grid">
        <Link href={"https://www.facebook.com/madoojapan/"}>
          <div className="grid-dot">
            <figure className="effect-honey bg-yellow p-04" id="fig004">
              <div className="heading-proj stroke">
                <h1>MADOO JAPAN</h1>
              </div>
              <figcaption>
                <div className="a-project a-img-center">
                  <Image
                    loading="lazy"
                    src={Project4}
                    alt="MADOO JAPAN"
                    className="img-fluid"
                  />
                </div>
              </figcaption>
              <div className="text-proj line-single">
                <p>
                  Our fresh, original contents from Japan deliver to you just
                  right on your screen.
                </p>
              </div>
            </figure>
          </div>
        </Link>
      </div>

      <div className="col grid">
        <Link href="/project/ai_translation">
          <div className="grid-dot">
            <figure className="effect-honey bg-green p-05" id="fig005">
              <div className="heading-proj">
                <h1>AI TRANSLATION</h1>
              </div>
              <figcaption>
                <div className="a-project">
                  <Image
                    loading="lazy"
                    src={Project5}
                    alt="AI TRANSLATION"
                    className="img-fluid"
                  />
                </div>
              </figcaption>
              <div className="text-proj line-single">
                <p>
                  Reduce your translation cost with our AI Translation Fast.
                  Smart. High Quality. Reasonable.
                </p>
              </div>
            </figure>
          </div>
        </Link>
      </div>

      <div className="col grid">
        <Link href="/project/itp_itic">
          <div className="grid-dot">
            <figure className="effect-honey bg-yellow p-06" id="fig006">
              <div className="heading-proj stroke">
                <h1>ITP ASIA x iTIC</h1>
              </div>
              <figcaption>
                <div className="a-project">
                  <Image
                    loading="lazy"
                    src={Project6}
                    alt="ITP ASIA x iTIC"
                    className="img-fluid"
                  />
                </div>
              </figcaption>
              <div className="text-proj line-single">
                <p>
                  We support iTIC for the proficient AI Translation to optimize
                  the quality of traffic news in content and time consume.
                </p>
              </div>
            </figure>
          </div>
        </Link>
      </div>

      <div className="col grid">
        <Link href={"https://www.facebook.com/prorod.official"}>
          <div className="grid-dot">
            <figure className="effect-honey bg-pink p-07" id="fig007">
              <div className="heading-proj">
                <h1>PROROD</h1>
              </div>
              <figcaption>
                <div className="a-project a-img-center">
                  <Image
                    loading="lazy"
                    src={Project7}
                    alt="PROROD"
                    className="img-fluid"
                  />
                </div>
              </figcaption>
              <div className="text-proj line-single">
                <p>
                  All of what you would love to know about cars brought to you
                  right at the tip of your finger.
                </p>
              </div>
            </figure>
          </div>
        </Link>
      </div>

      <div className="col grid">
        <Link href={"https://www.facebook.com/KINRAIDEEOFFICE"}>
          <div className="grid-dot">
            <figure className="effect-honey bg-blue p-08" id="fig008">
              <div className="heading-proj stroke">
                <h1>
                  KIN RAI DEE
                  <br />
                  TEE OFFICE
                </h1>
              </div>
              <figcaption>
                <div className="a-project r-img-center">
                  <Image
                    loading="lazy"
                    src={Project8}
                    alt="KIN RAI DEE"
                    className="img-fluid"
                  />
                </div>
              </figcaption>
              <div className="text-proj line-twin">
                <p>
                  Don't know what to eat? Craving for new food? Our page will
                  perfectly be your eating buddy.
                </p>
              </div>
            </figure>
          </div>
        </Link>
      </div>

      <div className="col grid">
        <Link href="/project/welcomeapp">
          <div className="grid-dot">
            <figure className="effect-honey bg-pink p-10" id="fig010">
              <div className="heading-proj stroke">
                <h1>
                  WELCOME
                  <br />
                  APPLICATION
                </h1>
              </div>
              <figcaption>
                <div className="a-project">
                  <Image
                    loading="lazy"
                    src={Project10}
                    alt="WELCOME APPLICATION"
                    className="img-fluid"
                  />
                </div>
              </figcaption>
              <div className="text-proj line-twin">
                <p>
                  Welcome your visitors with our innovative application that
                  will definitely impress your guests
                </p>
              </div>
            </figure>
          </div>
        </Link>
      </div>

      {/* <div className="col grid">
        <Link href="/project/printing">
          <div className="grid-dot">
            <figure className="effect-honey bg-yellow p-11" id="fig011">
              <div className="heading-proj">
                <h1>PRINTING</h1>
              </div>
              <figcaption>
                <div className="a-project a-img-center">
                  <Image
                    loading="lazy"
                    src={Project11}
                    alt="PRINTING"
                    className="img-fluid"
                  />
                </div>
              </figcaption>
              <div className="text-proj line-single">
                <p>
                  With 20 years of printing solution provider in Thailand, We
                  deliver you our wide-variety, high-quality printing support.
                </p>
              </div>
            </figure>
          </div>
        </Link>
      </div> */}

      <div className="col grid">
        <Link href="/project/premium_goods_production">
          <div className="grid-dot">
            <figure className="effect-honey bg-green p-12" id="fig012">
              <div className="heading-proj stroke">
                <h1>PREMIUM GOODS PRODUCTION</h1>
              </div>
              <figcaption>
                <div className="a-project ">
                  <Image
                    loading="lazy"
                    src={Project12}
                    alt="PREMIUM GOODS PRODUCTION"
                    className="img-fluid mb-4"
                  />
                </div>
              </figcaption>
              <div className="text-proj line-twin">
                <p>
                  Any ideas of premium goods production, We support from the
                  start to finish.
                </p>
              </div>
            </figure>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Project;
