export const convertDate = (date: any) => {
  const d = new Date(date);
  let day: any = d.getDate();
  let month: any = d.getMonth();
  if (day <= 9) {
    day = "0" + day;
  }
  const year = d.getFullYear();

  const arrayOfMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const post_date = day + " " + arrayOfMonth[month] + " " + year;

  return post_date;
};

const filter = (
  data: any,
  isActiveLang: any,
  isActiveCategory: any,
  isActiveService: any
) => {
  let news: any = [];
  if (isActiveLang == "English") {
    if (data.length > 0) {
      data.map((value: any, key: any) => {
        if (value.status == "Publish") {
          news.push({
            news_id: value.news_id,
            thumbnail: value.thumbnail,
            topic: value.topic,
            status: value.status,
            category: value.category,
            service: value.service,
            short_description: value.short_description,
            post_date: convertDate(value.post_date),
          });
        }
      });
    }
  } else if (isActiveLang == "Japanese") {
    if (data.length > 0) {
      data.map((value: any, key: any) => {
        if (value.status_jp == "Publish") {
          news.push({
            news_id: value.news_id,
            thumbnail: value.thumbnail,
            topic: value.topic_jp,
            status: value.status_jp,
            category: value.category,
            service: value.service,
            short_description: value.short_description_jp,
            post_date: convertDate(value.post_date),
          });
        }
      });
    }
  } else if (isActiveLang == "Thai") {
    if (data.length > 0) {
      data.map((value: any, key: any) => {
        if (value.status_th == "Publish") {
          news.push({
            news_id: value.news_id,
            thumbnail: value.thumbnail,
            topic: value.topic_th,
            status: value.status_th,
            category: value.category,
            service: value.service,
            short_description: value.short_description_th,
            post_date: convertDate(value.post_date),
          });
        }
      });
    }
  }

  let rows: any = [];
  if (news.length > 0) {
    news.map((value: any, key: any) => {
      if (isActiveCategory != "All" && isActiveService == "All") {
        if (
          value.category.toLowerCase().search(isActiveCategory.toLowerCase()) !=
          -1
        ) {
          rows.push({
            news_id: value.news_id,
            thumbnail: value.thumbnail,
            topic: value.topic,
            status: value.status,
            category: value.category,
            service: value.service,
            short_description: value.short_description,
            post_date: convertDate(value.post_date),
          });
        }
      } else if (isActiveCategory == "All" && isActiveService != "All") {
        if (
          value.service.toLowerCase().search(isActiveService.toLowerCase()) !=
          -1
        ) {
          rows.push({
            news_id: value.news_id,
            thumbnail: value.thumbnail,
            topic: value.topic,
            status: value.status,
            category: value.category,
            service: value.service,
            short_description: value.short_description,
            post_date: convertDate(value.post_date),
          });
        }
      } else if (isActiveCategory != "All" && isActiveService != "All") {
        if (
          value.category.toLowerCase().search(isActiveCategory.toLowerCase()) !=
            -1 &&
          value.service.toLowerCase().search(isActiveService.toLowerCase()) !=
            -1
        ) {
          rows.push({
            news_id: value.news_id,
            thumbnail: value.thumbnail,
            topic: value.topic,
            status: value.status,
            category: value.category,
            service: value.service,
            short_description: value.short_description,
            post_date: convertDate(value.post_date),
          });
        }
      } else {
        rows.push({
          news_id: value.news_id,
          thumbnail: value.thumbnail,
          topic: value.topic,
          status: value.status,
          category: value.category,
          service: value.service,
          short_description: value.short_description,
          post_date: convertDate(value.post_date),
        });
      }
    });
  }
  return rows;
};

export const filterDetail = (data: any, id: any, lang: any) => {
  let rows: any = {
    news_id: "",
    thumbnail: "",
    topic: "",
    status: "",
    category: "",
    service: "",
    short_description: "",
    post_date: "",
  };
  data.map((value: any, key: any) => {
    if (value.news_id == id) {
      if (lang == "English") {
        rows.news_id = value.news_id;
        rows.thumbnail = value.thumbnail;
        rows.topic = value.topic;
        rows.status = value.status;
        rows.category = value.category;
        rows.service = value.service;
        rows.short_description = value.short_description;
        rows.detail = value.detail;
        rows.post_date = convertDate(value.post_date);
      } else if (lang == "Japanese") {
        rows.news_id = value.news_id;
        rows.thumbnail = value.thumbnail;
        rows.topic = value.topic_jp;
        rows.status = value.status_jp;
        rows.category = value.category;
        rows.service = value.service;
        rows.short_description = value.short_description_jp;
        rows.detail = value.detail_jp;
        rows.post_date = convertDate(value.post_date);
      } else if (lang == "Thai") {
        rows.news_id = value.news_id;
        rows.thumbnail = value.thumbnail;
        rows.topic = value.topic_th;
        rows.status = value.status_th;
        rows.category = value.category;
        rows.service = value.service;
        rows.short_description = value.short_description_th;
        rows.detail = value.detail_th;
        rows.post_date = convertDate(value.post_date);
      }
    }
  });
  if (rows.status == "Draft") {
    location.href = "/news";
  }

  return rows;
};

export default filter;
