import { NextPage } from "next/types";
import React, { useState } from "react";
import { appDispatch, appSelector } from "@/store/hooks";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import MainService from "@/components/MainService";
import Client from "@/components/slideclient";
import News from "@/components/NewsBox";
import Project from "@/components/Project";

import Image from "next/image";
import Link from "next/link";
import LogoVission from "@/public/static/images/vission_logo.png";
import TextWeDo from "@/public/static/images/text-we-do.png";
import EastIcon from "@mui/icons-material/East";
import TextOurProject from "@/public/static/images/text-our-p.png";
import TextNews from "@/public/static/images/text-news.png";
import { getNews } from "@/features/admin/news";
import filter from "@/features/news";

import { Slide } from "react-slideshow-image";

function Index() {
  const [scrollTop, setScrollTop] = React.useState(0);
  // const handleScroll = (event: any) => {
  //   console.log(event.currentTarget.scrollTop);
  //   setScrollTop(event.currentTarget.scrollTop);
  // };
  const dispatch = appDispatch();
  const [isActiveLang, setIsActiveLang] = useState<any>("English");
  const [isActiveCategory, setIsActiveCategory] = useState<any>("All");
  const [isActiveService, setIsActiveService] = useState<any>("All");
  const [pagination, setPagination] = useState<any>(1);
  const perPage = 9;
  const { data } = appSelector((state) => state.news);
  let rows: any = [];
  if (data.length > 0) {
    rows = filter(data, isActiveLang, isActiveCategory, isActiveService);
  }
  React.useEffect(() => {
    dispatch(getNews(""));
  }, [dispatch]);

  React.useEffect(() => {
    const text001: any = document.getElementById("text001");
    const text002: any = document.getElementById("text002");

    const img001: any = document.getElementById("img001");
    var dist = 100;
    const handleScroll = (event: any) => {
      if (!checkVisible(text001, dist, "below")) {
        text001.classList.add("animate__fadeInDown");
      } else {
        text001.classList.remove("animate__fadeInDown");
      }
      if (!checkVisible(text002, dist, "below")) {
        text002.classList.add("animate__fadeInUp");
      } else {
        text002.classList.remove("animate__fadeInUp");
      }

      if (!checkVisible(img001, dist, "below")) {
        img001.classList.add("animate__fadeIn");
      } else {
        img001.classList.remove("animate__fadeIn");
      }

      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    function checkVisible(elm: any, threshold: any, mode: any) {
      threshold = threshold || 0;
      mode = mode || "visible";

      var rect = elm.getBoundingClientRect();
      var viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      );
      var above = rect.bottom - threshold < 0;
      var below = rect.top - viewHeight + threshold >= 0;

      return mode === "above"
        ? above
        : mode === "below"
        ? below
        : !above && !below;
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const images = [
    "/static/images/home_banner_01.webp",
    "/static/images/home_banner_02.webp",
    "/static/images/home_banner_03.webp",
  ];

  return (
    <div className="wrapper">
      <Header />
      <section className="carousel">
        <section className="itp__carousel">
          <Slide autoplay={true}>
            <div className="each-slide-effect">
              <div style={{ backgroundImage: `url(${images[0]})` }}></div>
            </div>
            <div className="each-slide-effect">
              <div style={{ backgroundImage: `url(${images[1]})` }}></div>
            </div>
            <div className="each-slide-effect">
              <div style={{ backgroundImage: `url(${images[2]})` }}></div>
            </div>
          </Slide>
        </section>
        <div className="scroll-downs">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
          <p className="text-red blink-soft">Let's Explore!</p>
        </div>
      </section>

      <section className="vission_main">
        <div className="container">
          <div className="row">
            <div className="flex-column">
              <div className="w-100 d-flex justify-content-center">
                <Image
                  loading="lazy"
                  src={LogoVission}
                  alt="vission"
                  className="vission_img animate__animated"
                  width={400}
                  id="img001"
                />
              </div>
              <div className="v_text d-flex justify-content-center  w-100 mt-4">
                <div className="static-txt">With the spirit of</div>
                <h2
                  className="dynamic-txts animate__animated text-blob"
                  data-text="OMOTENASHI"
                  id="text001"
                >
                  OMOTENASHI
                </h2>
              </div>
              <div className="v_text d-flex justify-content-center  w-100 ">
                <div className="static-txt">We go </div>
                <h2
                  className="dynamic-txts animate__animated text-blob"
                  data-text="BEYOND"
                  id="text002"
                >
                  BEYOND
                </h2>
                <div className="static-txt"> your expectation</div>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <Link className="link grow_skew_forward" href="/ourstory/overview/">
                  OUR STORY <EastIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="box_what-we-do">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-heading_img pt-5 px-5">
              <div className="d-flex justify-content-end">
                <Image
                  loading="lazy"
                  src={TextWeDo}
                  alt="WHAT WE DO"
                  className="me-5"
                />
              </div>
            </div>
            <div className="col-12 p-0 main_service-box">
              <MainService />
            </div>
          </div>
        </div>
      </section>

      <section className="box_our_project py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 text-heading_img">
              <div className="d-flex justify-content-center">
                <Image
                  loading="lazy"
                  src={TextOurProject}
                  alt="Our Project"
                  className="h-auto w-100"
                />
              </div>
            </div>
          </div>
          <Project />
        </div>
      </section>
      {/* <section className="s-client">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Client />
            </div>
          </div>
        </div>
      </section> */}

      <section className="News_Blog py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 text-heading_img py-5">
              <div className="d-flex justify-content-center">
                <Image
                  loading="lazy"
                  src={TextNews}
                  alt="Blog & NEWS"
                  className="h-auto w-100"
                />
              </div>
            </div>
          </div>

          <News
            rows={rows}
            page={pagination}
            perPage={perPage}
            lang={isActiveLang}
          />
          <div className="row">
            <div className="col-12 text-center py-5">
              <div className="d-flex justify-content-center align-items-center">
                <Link
                  className="link link-inner grow_skew_forward"
                  href="/news"
                >
                  Blog & News <EastIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Index;
